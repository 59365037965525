<template>
  <div class="page">
    <Navbar title="车辆信息" type="SMP"/>
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="car.brand"
          label="车辆品牌"
          placeholder="请输入车辆品牌"
          show-word-limit
          :rules="[{ required: true, message: '请输入车辆品牌' }]"
        />
        <van-field
          v-model="car.number"
          label="车牌号码"
          placeholder="请输入车牌号码"
          show-word-limit
          :rules="[{ required: true, message: '请输入车牌号码' }]"
        />
        <van-field
          v-model="car.worth"
          label="车辆价值(万)"
          placeholder="请输入车辆价值"
          show-word-limit
          :rules="[{ required: true, message: '请输入车辆价值' }]"
        />
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Tag, Form, CellGroup, Field, Popup, Area } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    [Tag.name]: Tag,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Area.name]: Area
  },

  data () {
    return {
      personCode: '',
      showRegion: false,
      seq: '',
      loadingShow: false,
      car: { brand: '', number: '', worth: '' }
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrieveCar()
  },
  methods: {
    async retrieveCar () {
      var pd = { personCode: this.personCode, key: 'PSN_CAR_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.car.brand = content.brand
        this.car.number = content.number
        this.car.worth = content.worth
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_CAR_ITEM', content: JSON.stringify(this.car), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  .btn {
    margin-top: 10px;
  }
}
</style>
